import React from "react"
import {
  Typography,
  Container,
  Grid,
  Box,
  Card,
  CardContent,
  CardMedia,
  Button,
  Link,
  CardActionArea,
} from "@material-ui/core"
import _ from "lodash"
import { makeStyles } from "@material-ui/core/styles"
import { RoomOutlined, LanguageOutlined } from "@material-ui/icons"

import LayoutComponent from "../../../../components/layout.component"
import theme from "../../../../theme"
import SEO from "../../../../components/seo.component"

const data = [
  {
    tags: [
      {
        name: "UX Design",
      },
      {
        name: "UX Practices",
      },
      {
        name: "UX Inspiration",
      },
    ],
    name: "Rillusion",
    address:
      "5th floor, Gokul Arcade, 2, Sardar Patel Rd, Baktavatsalm Nagar, Adyar, Chennai, Tamil Nadu 600020",
    type: "ui ux agency",
    cover: "https://uxofeverything.com/strapi/uploads/c8_ee9312f087.jpg",
    href: "/in/chennai/ui-ux-agency/rillusion",
    website: "https://www.rillusion.com/",
    desc: `Rillusion is currently
    <b>the best UI UX design company in Chennai</b>. The design
    company was founded in 2010 with a goal to design digital
    products that will help put a client's business on the map. The
    company currently not only designs apps for mobiles and web, but
    it also offers website design and development services. The team
    at Rillusion is also knowledgeable in advanced technologies like
    the Internet of Things and can create IoT apps as well as
    develop apps that are compatible with wearable tech. Ever since
    the inception of the company, the team has managed to complete
    500 projects and have worked with several top clients, namely,
    Sodexo, Armour, Ovenfresh, HDFC Bank, Axis Bank, etc.`,
  },
  {
    tags: [
      {
        name: "UX Design",
      },
      {
        name: "UX Practices",
      },
      {
        name: "UX Inspiration",
      },
    ],
    name: "Originux",
    address:
      "3rd Floor, No 5, 1st Cross Rd, K.R.Colony, Krishna Reddy Colony, Domlur, Bengaluru, Karnataka 560071",
    type: "ui ux agency",
    cover: "https://uxofeverything.com/strapi/uploads/c7_c7502ab8ac.jpg",
    href: "/in/chennai/ui-ux-agency/originux",
    website: "https://www.originux.com/",
    desc: `The Bangalore based UI UX design company has made a place in the
    list of <b>top 10 UI UX design companies in Chennai</b>. The
    company, founded in 2018, did not take too long to make a place
    in the competitive tech design industry, thanks to the
    leadership of Vaishnavi Reddy and a team of expert designers and
    coders. Although the company is headquartered in Bangalore, the
    company also operates in Chennai, San Francisco, Dubai, and
    Manchester. The Originux UX team has a collective experience of
    80 years, which allows them to create foolproof design and
    marketing strategies. The team follows design sprints and
    provides prototypes to ensure the end product is perfect.`,
  },
  {
    tags: [
      {
        name: "UX Design",
      },
      {
        name: "UX Practices",
      },
      {
        name: "UX Inspiration",
      },
    ],
    name: "Indus Net Technologies Pvt Ltd",
    address:
      "4th Floor, SDF Building Saltlake Electronic Complex, Kolkata, West Bengal 700091",
    type: "ui ux agency",
    cover: "https://uxofeverything.com/strapi/uploads/c5_51212bc176.jpg",
    href: "/in/chennai/ui-ux-agency/indusnettechnologiespvtltd",
    website: "https://www.indusnet.co.in/",
    desc: `Third, on the list of
    <b>top 10 UI UX design companies in Chennai</b> is the
    award-winning design company Indus Net Technologies. The company
    has been providing end to end digital solutions for businesses
    since 1997. Ever since it was founded, the company has grown to
    house over 750 tech professionals today. The team at Indus Net
    Technologies takes pride in the fact that they provide simple
    yet sustainable solutions that help clients to achieve digital
    success. The team has an analytics-driven approach. With
    branches in almost all prime cities across the Indian
    subcontinent as well as abroad, the team has worked with several
    clients from Induslnd Bank to Government of India to Tesco Bank
    to Cipla to Dr. Reddy's.`,
  },
  {
    tags: [
      {
        name: "UX Design",
      },
      {
        name: "UX Practices",
      },
      {
        name: "UX Inspiration",
      },
    ],
    name: "Lollypop",
    address:
      "#677, 9th Main Rd, Sector 7, HSR Layout, Bengaluru, Karnataka 560102",
    type: "ui ux agency",
    cover: "https://uxofeverything.com/strapi/uploads/c6_1a7dbbbcbd.jpg",
    href: "/in/chennai/ui-ux-agency/lollypop",
    website: "https://www.lollypop.design/",
    desc: `Lollypop was founded by Mr. Anil Reddy back in 2013. The
    company's key strength lies in front-end development, experience
    design, and interface design. With a tagline 'crafting sweet
    experiences', the company was founded to create digital products
    that would put a smile on the user's faces. The team has worked
    for clients coming from different countries and has experience
    in building digital products for various industries, from
    healthcare to education to food to e-commerce to real estate and
    IT. The team provides bespoke designs to clients after learning
    in-depth about their business needs. The team has delivered
    successful products for both start-ups and established brands
    like Aditya Birla, Bajaj Finserv, and Stanford University, to
    name a few.`,
  },
  {
    tags: [
      {
        name: "UX Design",
      },
      {
        name: "UX Practices",
      },
      {
        name: "UX Inspiration",
      },
    ],
    name: "Commutatus",
    address:
      "Spencer Plaza, 715-A, Suite No.663, 7th Floor, Anna Salai, Chennai, Tamil Nadu 600008",
    type: "ui ux agency",
    cover: "https://uxofeverything.com/strapi/uploads/c2_0b46581eaf.jpg",
    href: "/in/chennai/ui-ux-agency/commutatus",
    website: "http://www.commutatus.com/",
    desc: `The company was established in the year 2014, and in just a few
    years, it has earned a place in{" "}
    <b>the top 10 UI UX design companies in Chennai</b> due to the
    team's ability to stay updated with the latest design trends and
    to combine latest technologies with innovative designs. The name
    of the company is derived from a Latin word, meaning change,
    which is in line with the team's ability to keep up with changes
    in the tech industry. Based in Chennai, the team has worked on
    several successful projects like Mine Mentor, Alley, Leanspoon,
    AIESEC, Tested, and Gehna. The Commutatus team has taken on
    varied projects over the past few years, which further goes on
    to show their strengths in developing different products for
    different industries.`,
  },
  {
    tags: [
      {
        name: "UX Design",
      },
      {
        name: "UX Practices",
      },
      {
        name: "UX Inspiration",
      },
    ],
    name: "Ionixx Technologies",
    address:
      "No. L 25 (Second Floor), Dr. Vikram Sarabhai Instronics Estate, SRP Tools Thiruvanmiyur, Dr. Vasi Estate, Phase II, Tharamani, Chennai, Tamil Nadu 600041",
    type: "ui ux agency",
    cover: "https://uxofeverything.com/strapi/uploads/c1_7945260510.jpg",
    href: "/in/chennai/ui-ux-agency/ionixxtechnologies",
    website: "https://www.ionixxtech.com/",
    desc: `Ionixx Technologies have been in the design industry for about
    seven years now. The collective experience of the team at Ionixx
    Technologies is over 300 years. The design company provides end
    to end digital solutions for both start-ups and big players.
    Headquartered in Los Angeles, the company also operates in
    Chennai. Their tech team is proficient in DevOps and Blockchain
    and can develop custom enterprise apps that are unique and
    cloud-enabled to help your company take a step further in the
    digital world. Their roster of clients includes bigwigs like
    Chola, Shikhar Microfinance, Bitsian, Dubuqu, Unum, Bhooya! and
    Voyaj. They were awarded the Top Developers Clutch India 2019
    and Goodfirms Top Software Development Company.`,
  },
  {
    tags: [
      {
        name: "UX Design",
      },
      {
        name: "UX Practices",
      },
      {
        name: "UX Inspiration",
      },
    ],
    name: "Zencode Technologies",
    address:
      "III Floor, NCR Complex, 580/B, Sector 6, HSR Layout, Bengaluru, Karnataka 560102",
    type: "ui ux agency",
    cover: "https://uxofeverything.com/strapi/uploads/c3_3ab6649463.jpg",
    href: "/in/chennai/ui-ux-agency/zencodetechnologies",
    website: "https://www.zencode.guru/",
    desc: `Zencode Technologies is headquartered in Singapore but operates
    in 3 other cities- Chennai, Hong Kong, and Bangalore. The team
    at Zencode Technologies specializes in using advanced
    technologies like big data, machine learning, and natural
    language processing. The team has worked with government
    agencies and MNCs and also has experience working in different
    sectors like food, healthcare, logistics, finance, and
    education. Zencode Technologies provides flexible digital
    solutions to businesses to adapt to market changes. They have
    worked on 250 plus projects and have developed 50 plus digital
    products. Their roster of clients includes Telstra, L'oreal,
    Ezlink, DBS, Expedia, Braintree, etc.`,
  },
  {
    tags: [
      {
        name: "UX Design",
      },
      {
        name: "UX Practices",
      },
      {
        name: "UX Inspiration",
      },
    ],
    name: "Skcript",
    address:
      "1579 A, 15th Main Rd, Bharathi Colony, J Block, Bharathi Nagar, Anna Nagar, Chennai, Tamil Nadu 600040",
    type: "ui ux agency",
    cover: "https://uxofeverything.com/strapi/uploads/c4_55d2224a75.jpg",
    href: "/in/chennai/ui-ux-agency/skcript",
    website: "https://www.skcript.com/",
    desc: `Skcript is not only a UI UX design agency but also a digital
    consulting firm that was founded in the year 2013. The company
    developed it's very own Product Discovery Workshop which helps
    companies kickstart their digital transformation and build MVPs.
    For startup companies, the Product Discovery Workshop helps to
    build MVPs within just a span of a few weeks. One of the team's
    focus is to improve business efficiency. Skcript can help
    established companies automate the process of their business
    that can improve the company's overall performance through
    Robotic Process Automa. The team at Skcript is proficient in
    advanced coding languages like Ruby on Rails and has experience
    working with Artificial Intelligence. The company also offers a
    14 day trial period offer to new customers. The team has
    collaborated with Intel, Bosch, Gerab, Prudence Software,
    Microsoft, and Google, to name a few in the past.`,
  },
  {
    tags: [
      {
        name: "UX Design",
      },
      {
        name: "UX Practices",
      },
      {
        name: "UX Inspiration",
      },
    ],
    name: "W2S Solutions",
    address:
      "No.1, 1st floor, MRK Arcade, 200 Feet Radial Rd, Oggiyamduraipakkam, Tamil Nadu 600097",
    type: "ui ux agency",
    cover: "https://uxofeverything.com/strapi/uploads/c10_b1202c38a8.jpg",
    href: "/in/chennai/ui-ux-agency/w2ssolutions",
    website: "https://www.w2ssolutions.com/",
    desc: `W2S Solutions is a full-stack UI UX design and development
    agency that was established in 2010. Over 20 years in the
    industry, the company has built over 25 successful apps for both
    mobile and web platforms. The team mainly provides digital
    solutions for startups and mid-sized companies. The company has
    headquarters in Chennai but has since opened offices in Dublin,
    Austin, Calgary, Dubai, and Waterloo. The company has a
    reputation for thinking outside the box and coming up with
    unique ideas that help the client's business. The team has
    worked across various industries- healthcare, finance, real
    estate, automotive, logistics, agriculture, weather, etc. The
    team offers cloud integration with its apps and uses Natural
    language processing and Artificial Intelligence. Over the years,
    they have worked with Starbucks, Techmart, Samsung, Blackberry,
    Enmax, Paragon and Sony.`,
  },
  {
    tags: [
      {
        name: "UX Design",
      },
      {
        name: "UX Practices",
      },
      {
        name: "UX Inspiration",
      },
    ],
    name: "Vamos Digital",
    address:
      "224/1 Defence Colony, 2nd Main, Bengaluru, Karnataka, India 560038",
    type: "ui ux agency",
    cover: "https://uxofeverything.com/strapi/uploads/c9_4e942b52e1.jpg",
    href: "/in/chennai/ui-ux-agency/vamosdigital",
    website: "https://www.vamosdigital.in/",
    desc: `The last on the list
    <b>top 10 UI UX design companies in Chennai</b>, Vamos Digital,
    became a fully stacked UI UX design agency in just four years
    under the leadership of Creative Director and Founder Pooja
    Goyal. The team at Vamos Digital has adopted an ROI driven
    approach, and the entire team has a collective experience of
    over eight years. The team likes taking up challenges that
    reflect in their portfolio. The team has gathered experience
    working in various sectors, from entertainment to retail, to
    social to finance. The company works with startups as well as
    established brands like Zee Entertainment, StarTv, Lavazza,
    Coffee Klay, Huga Ice Creams, etc. The company has earned a
    feature on The Hindu and Your Story and has multiple awards for
    branding and launching creative digital products.`,
  },
]

const useStyles = makeStyles(theme => ({
  cardHeight: {
    height: "100%",
    borderBottom: `5px solid ${theme.palette.background.apricot}`,
  },
}))

const UiUxAgency = ({ location }) => {
  const classes = useStyles(theme)

  return (
    <LayoutComponent>
      <SEO title="The best UI/UX agency in Chennai" canonical={location.href} />
      <Box pt={5} pb={5}>
        <Container maxWidth="xl">
          <Grid container direction="row">
            <Grid item>
              <Typography variant="h5" component="h2">
                The best UI/UX agency in Chennai
              </Typography>
              <Box pt={2} pb={2}>
                <Typography variant="body2" component="p">
                  UI and UX design form an integral part of your digital
                  success, which is why companies, big or small, need to invest
                  in a good Ui UX agency in Chennai. But how do you know which
                  one is the best UI UX design company in Chennai? With hundreds
                  of options, it can get quite confusing for someone who has no
                  prior knowledge about UI and UX, but do not worry; we have
                  compiled a list of UI UX design agencies in Chennai that are
                  considered to be the best in the business. The list of top UI
                  UX agencies in Chennai will help you make an informed
                  decision, and this will not only save your time and money, but
                  it will ensure that you do not end up working with an
                  incompetent UI UX company. The list of UI UX design agencies
                  in Chennai below contains all the important details that you
                  may need to make a choice.
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Box pt={5}>
            <Grid
              container
              spacing={3}
              direction="row"
              justify="center"
              alignItems="center"
            >
              {_.map(data, (node, i) => {
                return (
                  <Grid item lg={10} key={i}>
                    <Card className={classes.cardHeight}>
                      <Link
                        href={node.href}
                        component={CardActionArea}
                        color="textPrimary"
                        underline="none"
                      >
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          alignItems="center"
                        >
                          <Grid item md={4}>
                            <CardMedia
                              component="img"
                              image={node.cover}
                              title="image"
                            />
                          </Grid>
                          <Grid item md={8}>
                            <CardContent>
                              <Typography
                                gutterBottom
                                variant="body1"
                                component="h3"
                              >
                                {_.truncate(node.name, { length: 100 })}
                              </Typography>
                              <Typography
                                gutterBottom
                                variant="body1"
                                component="h4"
                              >
                                {node.type}
                              </Typography>
                              <Typography
                                gutterBottom
                                variant="body1"
                                component="p"
                                dangerouslySetInnerHTML={{
                                  __html: node.desc,
                                }}
                              />
                              <Box display="flex" flexDirection="row">
                                <LanguageOutlined fontSize="small" />
                                <Typography
                                  gutterBottom
                                  variant="body2"
                                  component="p"
                                >
                                  Website |{" "}
                                  <Link
                                    href={node.website}
                                    color="textPrimary"
                                    underline="none"
                                    target="_blank"
                                  >
                                    {node.website}
                                  </Link>
                                </Typography>
                              </Box>
                              <Box display="flex" flexDirection="row">
                                <RoomOutlined fontSize="small" />
                                <Typography variant="body2" component="p">
                                  Address | {node.address}
                                </Typography>
                              </Box>
                              <Box
                                display="flex"
                                flexWrap="wrap"
                                flexDirection="row"
                                p={1}
                              >
                                {_.map(node.tags, (tag, i) => {
                                  return (
                                    <Box p={1} key={i}>
                                      <Button
                                        size="small"
                                        color="secondary"
                                        variant="contained"
                                        key={i}
                                      >
                                        {tag.name}
                                      </Button>
                                    </Box>
                                  )
                                })}
                              </Box>
                            </CardContent>
                          </Grid>
                        </Grid>
                      </Link>
                    </Card>
                  </Grid>
                )
              })}
            </Grid>
          </Box>
        </Container>
      </Box>
    </LayoutComponent>
  )
}

export default UiUxAgency
